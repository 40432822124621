import { computed, reactive, toRefs } from 'vue';
import { useWindowSize } from '@vueuse/core';

const navDrawerState = reactive({
  isLeftNavOpen: true,
});

export function useUi () {
  const TOOLBAR_HEIGHT_PX = 64;
  const MOBILE_BREAKPOINT_PX = 1024;
  const { width, height } = useWindowSize();
  const windowHeightNoToolbar = computed(() => height.value - TOOLBAR_HEIGHT_PX);

  return {
    toolbarHeightPx: TOOLBAR_HEIGHT_PX,
    mobileBreakpointPx: MOBILE_BREAKPOINT_PX,
    windowWidth: width,
    windowHeight: height,
    windowHeightNoToolbar,
  }
};

export function useNavDrawer () {
  function toggleLeftNavDrawer (id) {
    navDrawerState.isLeftNavOpen = !navDrawerState.isLeftNavOpen;
  }

  return {
    ...toRefs(navDrawerState),
    toggleLeftNavDrawer,
  };
}

export function useEncounterActiveColor () {
  // #FAFAFA
  // const colorMap = {
  //   summary: 'red',
  //   triage: 'pink',
  //   subjective: 'orange',
  //   objective: 'yellow',
  //   assessment: 'green',
  //   plan: 'blue',
  //   billing: 'indigo',
  // };

  const colorMap = {
    summary: '#fafafa',
    triage: '#fafafa',
    subjective: '#fafafa',
    objective: '#fafafa',
    assessment: '#fafafa',
    plan: '#fafafa',
    billing: '#fafafa',
  };

  function getActiveColor (id) {
    return colorMap[id];  
  }

  return {
    getActiveColor,
  };
};
